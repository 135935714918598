var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "align-items": "center",
              },
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pa-0",
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "nowrap",
                    "align-items": "baseline",
                  },
                },
                [
                  _vm.title ===
                  _vm.$t("tableItems.reviewContract.stepThirdContent.title11")
                    ? _c("v-img", {
                        attrs: {
                          src: require("@/assets/img/title-item.png"),
                          "max-width": "10px",
                          "max-height": "20px",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold ml-2",
                      staticStyle: { "font-size": "large" },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "toggleOpenIcon",
                  staticClass: "arrow_open",
                  staticStyle: {
                    "font-size": "13px",
                    color: "#0069d8",
                    "text-decoration": "underline",
                    cursor: "pointer",
                  },
                  on: { click: _vm.toggleOpen },
                },
                [_vm._v("\n        " + _vm._s(_vm.toggleText) + "\n      ")]
              ),
            ],
            1
          ),
          _vm.title ===
          _vm.$t("tableItems.reviewContract.stepThirdContent.title11")
            ? _c("v-container", { staticClass: "pt-0" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "tableItems.reviewContract.stepThirdContent.explanation"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _c("v-container", { staticClass: "pt-0" }, [
            _c("div", { staticClass: "dotted-line" }),
            _vm.isOpen
              ? _c("table", { staticClass: "plan-table" }, [
                  _c(
                    "tbody",
                    _vm._l(_vm.records, function (record, key) {
                      return _c("tr", { key: key }, [
                        record.typeTitle && _vm.isExplanationItem(record.type)
                          ? _c(
                              "td",
                              {
                                staticClass: "header-color item-explanation",
                                attrs: {
                                  rowspan: _vm.recordRowspan(record.type),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleShowItemExplanation(
                                      record.type
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.splitMessageLine(
                                  _vm.$t(`header.planTable.${record.typeTitle}`)
                                ),
                                function (message, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticClass: "ma-0",
                                      staticStyle: {
                                        "word-wrap": "break-word",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(message) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : record.typeTitle
                          ? _c(
                              "td",
                              {
                                staticClass: "header-color",
                                attrs: {
                                  rowspan: _vm.recordRowspan(record.type),
                                },
                              },
                              _vm._l(
                                _vm.splitMessageLine(
                                  _vm.$t(`header.planTable.${record.typeTitle}`)
                                ),
                                function (message, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticClass: "ma-0",
                                      staticStyle: {
                                        "word-wrap": "break-word",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(message) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm.isExplanationItem(key)
                          ? _c(
                              "td",
                              {
                                staticClass: "header-color item-explanation",
                                attrs: {
                                  colspan: _vm.recordHeaderColspan(record),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleShowItemExplanation(key)
                                  },
                                },
                              },
                              _vm._l(
                                _vm.splitMessageLine(
                                  _vm.$t(`header.planTable.${key}`)
                                ),
                                function (message, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticClass: "ma-0",
                                      staticStyle: {
                                        "word-wrap": "break-word",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(message) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c(
                              "td",
                              {
                                staticClass: "header-color",
                                attrs: {
                                  colspan: _vm.recordHeaderColspan(record),
                                },
                              },
                              [
                                _vm.$te(`header.planTable.${key}`)
                                  ? _vm._l(
                                      _vm.splitMessageLine(
                                        _vm.$t(`header.planTable.${key}`)
                                      ),
                                      function (message, index) {
                                        return _c(
                                          "p",
                                          {
                                            key: index,
                                            staticClass: "ma-0",
                                            staticStyle: {
                                              "word-wrap": "break-word",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(message) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  : _vm._l(
                                      _vm.splitMessageLine(key),
                                      function (message, index) {
                                        return _c(
                                          "p",
                                          {
                                            key: index,
                                            staticClass: "ma-0",
                                            staticStyle: {
                                              "word-wrap": "break-word",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(message) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                              ],
                              2
                            ),
                        _c(
                          "td",
                          { attrs: { colspan: "3" } },
                          _vm._l(
                            _vm.splitMessageLine(record.value),
                            function (message, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "ma-0",
                                  staticStyle: { "word-wrap": "break-word" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(message) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("ItemExplanationDialog", {
        attrs: {
          maxWidth: 320,
          showDialog: _vm.showItemExplanation,
          title: _vm.explanationItemTitle,
          text: _vm.explanationItemText,
          negativeButtonTitle: _vm.$t("button.close"),
          onClickNegativeButton: _vm.toggleShowItemExplanation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }