<template>
  <v-container>
    <v-row>
      <v-col
        class="pb-0"
        style="display: flex; justify-content: space-between; align-items: center;"
      >
        <v-card-title
          class="pa-0"
          style="display: flex; flex-wrap: nowrap; align-items: baseline;"
        >
        <v-img
            v-if="
            title === $t('tableItems.reviewContract.stepThirdContent.title11')"
            src="@/assets/img/title-item.png"
            max-width="10px"
            max-height="20px"
        ></v-img>
          <span class="font-weight-bold ml-2" style="font-size: large;">
            {{ title }}
          </span>
        </v-card-title>
        <div
          ref="toggleOpenIcon"
          @click="toggleOpen"
          class="arrow_open"
          style="font-size: 13px; color: #0069d8; text-decoration:underline; cursor: pointer;"
        >
          {{ toggleText }}
        </div>
      </v-col>
      <v-container
        v-if="
          title === $t('tableItems.reviewContract.stepThirdContent.title11')
        "
        class="pt-0"
      >
        <span>{{
          $t('tableItems.reviewContract.stepThirdContent.explanation')
        }}</span>
      </v-container>

      <v-container class="pt-0">
        <div class="dotted-line"></div>
        <table v-if="isOpen" class="plan-table">
          <tbody>
            <tr v-for="(record, key) in records" :key="key">
              <!-- 1列目の項目名が存在する場合 -->
              <!-- 項目に下線を引き、ボタンクリックで項目説明のダイアログを表示する機能、ロジックのみ残す -->
              <td
                v-if="record.typeTitle && isExplanationItem(record.type)"
                class="header-color item-explanation"
                :rowspan="recordRowspan(record.type)"
                @click="toggleShowItemExplanation(record.type)"
              >
                <p
                  class="ma-0"
                  style="word-wrap: break-word;"
                  v-for="(message, index) in splitMessageLine(
                    $t(`header.planTable.${record.typeTitle}`)
                  )"
                  :key="index"
                >
                  {{ message }}
                </p>
              </td>
              <td
                v-else-if="record.typeTitle"
                class="header-color"
                :rowspan="recordRowspan(record.type)"
              >
                <p
                  class="ma-0"
                  style="word-wrap: break-word;"
                  v-for="(message, index) in splitMessageLine(
                    $t(`header.planTable.${record.typeTitle}`)
                  )"
                  :key="index"
                >
                  {{ message }}
                </p>
              </td>

              <!-- 2列目の項目名が存在する場合 -->
              <!-- 項目に下線を引き、ボタンクリックで項目説明のダイアログを表示する機能、ロジックのみ残す -->
              <td
                v-if="isExplanationItem(key)"
                class="header-color item-explanation"
                :colspan="recordHeaderColspan(record)"
                @click="toggleShowItemExplanation(key)"
              >
                <p
                  class="ma-0"
                  style="word-wrap: break-word;"
                  v-for="(message, index) in splitMessageLine(
                    $t(`header.planTable.${key}`)
                  )"
                  :key="index"
                >
                  {{ message }}
                </p>
              </td>
              <td
                v-else
                class="header-color"
                :colspan="recordHeaderColspan(record)"
              >
                <template v-if="$te(`header.planTable.${key}`)">
                  <p
                     class="ma-0"
                     style="word-wrap: break-word;"
                     v-for="(message, index) in splitMessageLine(
                    $t(`header.planTable.${key}`)
                  )"
                     :key="index"
                  >
                    {{ message }}
                  </p>
                </template>
                <template v-else>
                  <p
                    class="ma-0"
                    style="word-wrap: break-word;"
                    v-for="(message, index) in splitMessageLine(key)"
                    :key="index"
                  >
                    {{ message }}
                  </p>
                </template>
              </td>
              <!-- 3列目以降(前年同等条件プラン・おすすめ1・おすすめ2)の値を表示 -->
              <td colspan="3">
                <p
                  class="ma-0"
                  style="word-wrap: break-word;"
                  v-for="(message, index) in splitMessageLine(record.value)"
                  :key="index"
                >
                  {{ message }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-container>
    </v-row>

    <ItemExplanationDialog
      :maxWidth="320"
      :showDialog="showItemExplanation"
      :title="explanationItemTitle"
      :text="explanationItemText"
      :negativeButtonTitle="$t('button.close')"
      :onClickNegativeButton="toggleShowItemExplanation"
    />
  </v-container>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';
import ItemExplanationDialog from '@/components/organisms/contractor/ItemExplanationDialog.vue';

export default {
  components: {
    ItemExplanationDialog,
  },
  props: {
    title: String,
    contentPageName: String,
    records: Object,
  },
  data() {
    return {
      isOpen: true,
      toggleText: this.$t('button.close'),
      showItemExplanation: false,
      explanationItemText: '',
      explanationItemTitle: '',
      activePlanTab: 'planTab1',
    };
  },
  methods: {
    recordRowspan(type) {
      return Object.values(this.records).filter(record => record.type === type)
        .length;
    },
    recordHeaderColspan(record) {
      if (!record.typeTitle && !record.type) {
        return 2;
      }
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$refs.toggleOpenIcon.classList.remove('arrow_close');
        this.toggleText = this.$t('button.close');
      } else {
        this.$refs.toggleOpenIcon.classList.add('arrow_close');
        this.toggleText = this.$t('button.open');
      }
    },
    isExplanationItem(key) {
      return this.$te(this.targetI18n(key));
    },
    targetI18n(key) {
      return `tableItems.reviewContract.${this.contentPageName}.explanationItems.${key}`;
    },
    toggleShowItemExplanation(key) {
      this.showItemExplanation = !this.showItemExplanation;

      if (this.showItemExplanation) {
        this.explanationItemTitle = this.$t(`header.planTable.${key}`);
        this.explanationItemText = this.$t(this.targetI18n(key));
      } else {
        this.explanationItemTitle = '';
        this.explanationItemText = '';
      }
    },
  },
};
</script>
<style>
.plan-table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 5px;
  font-size: 14px;
  table-layout: fixed;
}

.plan-table th.active {
  background-color: #a9cbf4;
}

.plan-table th,
.plan-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

.plan-tab {
  cursor: pointer;
}

.header-color {
  background: #d5ebfe;
}

.dotted-line {
  border-top: 2px dotted #cccccc;
  margin-bottom: 10px;
}

.title-icon-rotate {
  transform: rotate(-90deg);
}

.item-explanation {
  text-decoration: underline;
  cursor: pointer;
  color: #0069d8;
}

/* 矢印アイコンの丸背景  */
.arrow_open {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  color: #0069d8;
  text-decoration: none;
}

/* 丸背景 + 矢印の表示  */
.arrow_open:before {
  content: '';
  width: 18px;
  height: 18px;
  background: #0069d8;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -3px;
  margin-top: -9px;
}

/* 表を開いている場合の矢印  */
.arrow_open:after {
  content: '';
  width: 6px;
  height: 6px;
  border: 0;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -2px;
  margin-left: 3px;
  transform: rotate(-45deg);
}

/* 表を閉じている場合の矢印  */
.arrow_close:after {
  content: '';
  width: 6px;
  height: 6px;
  border: 0;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -4px;
  margin-left: 3px;
  transform: rotate(135deg);
}

.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
